var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.feeSchedules,
          fields: _vm.fields,
          "is-data-from-model": false,
          "is-add-edit-sidbar": false,
          "copy-data": _vm.selecetedFeeScheduleCopy,
          "sidebar-data": _vm.selectedFeeSchedule,
          "get-by-id-on-edit-filter": function () {
            return false
          },
          "add-edit-link": {
            name: "fee-schedules-edit",
            params: { feeScheduleId: _vm.selecetedFeeScheduleCopy.id },
          },
        },
        on: {
          "update:copyData": function ($event) {
            _vm.selecetedFeeScheduleCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selecetedFeeScheduleCopy = $event
          },
          "update:sidebarData": function ($event) {
            _vm.selectedFeeSchedule = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedFeeSchedule = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "button",
                      variant: "primary",
                      size: "sm",
                      pill: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showModalPopup = true
                      },
                    },
                  },
                  [_vm._v("\n        + Add\n      ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(delete)",
            fn: function () {
              return [_c("span")]
            },
            proxy: true,
          },
          {
            key: "cell(action-left)",
            fn: function (data) {
              return [
                _c(
                  "b-link",
                  {
                    attrs: {
                      to: {
                        name: "fee-schedules-view-one",
                        params: { feeScheduleId: data.item.id },
                      },
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(40, 199, 111, 0.15)",
                            expression: "'rgba(40, 199, 111, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        staticClass: "d-flex px-0 mr-1",
                        attrs: { variant: "flat-primary", size: "sm" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "EyeIcon", size: "16" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-sm",
            "cancel-variant": "primary",
            centered: "",
            size: "sm",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.showModalPopup,
            callback: function ($$v) {
              _vm.showModalPopup = $$v
            },
            expression: "showModalPopup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center w-100 mt-2 mb-2" },
            [
              _c("h5", [
                _vm._v("Select The Fee Schedule Type You Would Like To Setup"),
              ]),
              _c(
                "b-link",
                {
                  attrs: {
                    to: {
                      name: "fee-schedules-add",
                      params: {
                        type: _vm.feeScheduleType.Medicare,
                        feeScheduleId: "new",
                      },
                    },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "button", variant: "primary", block: "" },
                    },
                    [_vm._v("\n          Medicare\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "b-link",
                {
                  attrs: {
                    to: {
                      name: "fee-schedules-add",
                      params: { type: _vm.feeScheduleType.Dynamic },
                    },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-1",
                      attrs: { type: "button", variant: "primary", block: "" },
                    },
                    [_vm._v("\n          Dynamic\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "b-link",
                { attrs: { to: { name: "fee-schedules-manual-start" } } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-1",
                      attrs: { type: "button", variant: "primary", block: "" },
                    },
                    [_vm._v("\n          Manual\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-1",
                  attrs: {
                    type: "button",
                    variant: "outline-primary",
                    block: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showModalPopup = false
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
          _c("b-card-text", { attrs: { else: "" } }, [
            _vm._v(_vm._s(_vm.result.message)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }